import { Action, Reducer } from '@reduxjs/toolkit'

export enum EFirebaseActions {
  UPDATE_REMOTE_CONFIG = 'update_remote_config',
}

export interface IDashboard {
  id: number
  name: string
}

export interface IFirebaseReducer {
  DASHBOARDS: IDashboard[]
}

const INITIAL_STATE = {
  DASHBOARDS: [],
}

export const FirebaseReducer: Reducer<IFirebaseReducer> = (
  state = INITIAL_STATE,
  action: Action | any,
) => {
  switch (action.type) {
    case EFirebaseActions.UPDATE_REMOTE_CONFIG:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
