import { AnyAction, combineReducers, configureStore as configure, Reducer } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { AppReducer, IAppReducer } from './app'
import { AuthReducer, IAuthReducer } from './auth'
import { FirebaseReducer, IFirebaseReducer } from './firebase'

export interface IStore {
  app: IAppReducer
  auth: IAuthReducer
  firebase: IFirebaseReducer
}

const persistConfig = {
  key: 'root',
  storage,
  devTools: process.env.NODE_ENV !== 'production',
}

const configureStore = () => {
  const reducer = persistReducer(
    persistConfig,
    combineReducers({
      app: AppReducer,
      auth: AuthReducer,
      firebase: FirebaseReducer,
    }),
  )

  const store = configure({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  })

  const persister = persistStore(store)

  return { persister, store }
}

export const { store, persister } = configureStore()
