import React from 'react'

import 'react-app-polyfill/stable'
import 'core-js'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {Provider} from 'react-redux'
import {persister, store} from './redux/store'
import ReactDOM from 'react-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {initializeApp} from "firebase/app";
import axios from 'axios'
import {fetchAndActivate, getRemoteConfig, getString} from 'firebase/remote-config'
import {EFirebaseActions} from './redux/firebase'
import {OutputFormat, setDefaults} from "react-geocode";

axios.interceptors.request.use((config) => {
  if (!!!config["ignoreBearerToken"]) {
    const token = store.getState().auth.token
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const firebaseConfig = {
  apiKey: "AIzaSyDGamR-vqDG-J6DZhcSCVmyKJaj3T3Fny8",
  authDomain: "synas-app-d7dd7.firebaseapp.com",
  databaseURL: "https://synas-app-d7dd7-default-rtdb.firebaseio.com",
  projectId: "synas-app-d7dd7",
  storageBucket: "synas-app-d7dd7.appspot.com",
  messagingSenderId: "625614843361",
  appId: "1:625614843361:web:d167cef7b6a7732f0a36a0",
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

fetchAndActivate(remoteConfig).then(() => {
  const configs = JSON.parse(getString(remoteConfig, 'admin'));
  store.dispatch({ type: EFirebaseActions.UPDATE_REMOTE_CONFIG, payload: configs })
});

setDefaults({
  outputFormat: OutputFormat.JSON,
  key: process.env.REACT_APP_MAPS_API_KEY, // Your API key here.
  language: "pt", // Default language for responses.
  region: "br" // Default region for responses.
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persister}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
